import React from 'react';
import { isAuthenticated } from '../auth';
import { Unauthorized } from './Unauthorized';

export const Route = ({ component: Component, ...rest }: any) => {
  if (!isAuthenticated()) {
    return <Unauthorized />;
  }

  return <Component {...rest} />;
};

export const UnsafeRoute = ({ component: Component, ...rest }: any) => {
  return <Component {...rest} />;
};
