import React, { FC } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Paper, makeStyles, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Link } from 'gatsby';
import { UserBookingsHistoryQuery } from '../generated/graphql';
import { GraphQLErrorDisplay } from './GraphQLErrorDisplay';

const userBookingsHistoryQuery = gql`
  query userBookingsHistory($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        classBookingHistory(first: 100) {
          edges {
            node {
              id
              notes {
                id
                note
              }
              bookingTimeslot {
                startTime
                durationInMinutes
              }
              classTopic {
                id
                title
                lesson {
                  id
                  number
                  level {
                    id
                    number
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyle = makeStyles(theme => ({
  container: {
    position: 'relative',
    margin: theme.spacing(1),
    overflow: 'auto',
  },
  titleContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  bookingContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  notesContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
  link: {
    color: theme.palette.text.primary,
  },
}));

export interface UserBookingsHistoryProps {
  id: string;
}

export const UserBookingsHistory: FC<UserBookingsHistoryProps> = ({ id }) => {
  const styles = useStyle();
  const { data, loading, error } = useQuery<UserBookingsHistoryQuery>(
    userBookingsHistoryQuery,
    {
      variables: {
        id,
      },
    },
  );
  if (error) return <GraphQLErrorDisplay error={error} />;
  if (loading) return <div>Loading</div>;
  if (!data || data.node?.__typename !== 'User') return <div>Failed</div>;

  const bookings = data?.node.classBookingHistory?.edges?.map(edge => {
    const booking = edge?.node;
    const notes =
      booking?.notes.map(note => note.note).join('\n') ||
      'No notes for this class';

    const time = booking?.bookingTimeslot.startTime;

    return (
      <div key={booking?.id} className={styles.bookingContainer}>
        <Typography variant="h6" component="div">
          <Link
            className={styles.link}
            to={`/courseware/${booking?.classTopic?.lesson?.level.number}/${booking?.classTopic?.lesson?.level.id}`}
          >
            {`Level ${booking?.classTopic?.lesson?.level.number}, Lesson ${booking?.classTopic?.lesson?.number}`}
          </Link>
        </Typography>
        <Typography variant="caption" component="div">
          {booking?.classTopic?.title}
        </Typography>
        <Typography variant="caption" component="div">
          {DateTime.fromISO(time).toFormat('DDD')}
        </Typography>

        <Paper className={styles.notesContainer} variant="outlined">
          <Typography component="div" variant="body1">
            {notes}
          </Typography>
        </Paper>
      </div>
    );
  });

  return (
    <Paper className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography variant="h3" component="div">
          Recent Bookings
        </Typography>
      </div>
      {bookings?.length ? (
        bookings
      ) : (
        <div className={styles.bookingContainer}>
          <Typography variant="caption" component="div">
            No bookings history
          </Typography>
        </div>
      )}
    </Paper>
  );
};
